<template>
  <v-container class="fill-height d-flex align-start justify-center pt-12">
    <div class="mt-12">
      <v-row>
        <v-col cols="12" class="text-h5 text-center mb-12">
          Acesso temporario a outras interfaces
        </v-col>

        <v-col>
          <v-btn to="/gestor" outlined x-large block>
            Gestor
          </v-btn>
        </v-col>

        <v-col>
          <v-btn to="/cliente" outlined x-large block>
            Cliente
          </v-btn>
        </v-col>

        <v-col>
          <v-btn to="/colaborador" outlined x-large block>
            Colaborador
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss"></style>
